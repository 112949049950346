<template>
  <div class="contaiter">

    <!-- 导航 -->
     <div class="titleNav" v-if="showadd!=3">
        您当前所在的位置：网站首页 > 代叔信息 > <span class="spannav">出入仓</span>  
     </div>
      <div class="titleNav" v-if="showadd==3">
        您当前所在的位置：网站首页 > 代叔信息 >出入仓 > 入仓 > <span class="spannav">送货管理</span>  
     </div>
              <!-- 下单 -->
     <div v-if="showadd==2">
      <goodsItem :placeData="placeOrder" :isoverfy="isoverfy"  :showDeleteHid="showDeleteHid"/>
      
      <div class="queRenXia">
        <div class="item_list_banner_select">
          <div @click="clickRadite" :class="{raditebg:isRadite,radite:!isRadite}"></div>
        </div>
        <div class="submitQue" @click="goAdministration">
          确认下单
        </div>
      </div>
     </div>
     <!-- 申请入仓物品 -->
     <div v-if="showadd==1">
       <div class="item_list">
       <!-- 搜索区域 -->
       <div class="laoutSearch">
         <div class="search">
           <div class="inputSearch">
               <a-input-search
             placeholder="搜索商品"
            enter-button="查询"
            @search="onSearch"
    >
               <a-icon slot="prefix" type="search" />
            </a-input-search>
           </div>
           <div class="item_Right">
             <div class="item_RightList">
               <img src="../../image/ru_icon.png" alt="" srcset="">
               <span>
                 消息
               </span>
             </div>
             <div class="item_RightList">
               <img src="../../image/ru_sc.png" alt="" srcset="">
               <span>
                 收藏
               </span>
             </div>
             <div class="item_RightList">
               <img src="../../image/ru_gw.png" alt="" srcset="">
               <span>
                 购物车
               </span>
             </div>
             <div class="item_RightList">
               <img src="../../image/ru_wx.png" alt="" srcset="">
               <span>
                 小程序
               </span>
             </div>
           </div>
         </div>
       </div>
       <!-- 物品 -->
       <div class="item_list_data">
         <div class="item_list_banner" v-for="(item,i) in itemData" :key="i">
           <img src="../../image/ruc_banner.png" alt="" srcset="">
           <div class="item_list_list">
             <div class="item_list_miaos">
               {{item.title}}
             </div>
             <div class="item_list_bh">
               产品编号:{{item.bh}}
             </div>
             <div class="item_list_sub">
               <div class="item_list_gg">规格:{{item.gge}}</div>
               <div class="item_list_sq" @click="clickChanged">
                 申请入仓
               </div>
             </div>
           </div>
         </div>
       </div>
       <div class="xdNumber">
          <div class="selected-box">
            <div class="selected">
            <!-- <div class="selected_box">1231</div> -->
              <img src="../../image/selected.png" alt="">
            </div>
            <div class="select_text">已选择好数量</div>
          </div>
            <!-- 确认下单 -->
          <div class="submitxd" >下单</div>
       </div>
     </div>
     <div class="modalShow" v-if="showHiden">
       <div class="cloneX" @click="cloneX" >
         <img src="../../image/cloneX.png"  alt="" srcset="">
         
       </div>
        <img src="~@/image/curuCang.png" alt="" srcset="">
         <div class="modalShowImg">
            <div class="modalImg">
          <div>
            <div class="xzrcang">选择入仓信息</div>
            <div class="item-data">
                 填写入仓信息 <input class="item_input item_listin" type="text" >
            </div>
          </div>
          <div>
            <div class="item-data">
                 <input class="item_input item_inputt" type="text" >
                 <span class="xiang pin">箱</span>
                 <input class="item_input item_inputt" type="text" >
                 <span class="pin">瓶</span>
            </div>
          </div>
           <div>
            <div class="item-data">
                 填写批次号1 <input class="item_input item_listin" type="text" >
            </div>
            <div class="item-data">
                 填写批次号2 <input class="item_input item_listin" type="text" >
            </div>
            <div class="item-data item-dataBanner">
                 <span>生产日期</span> 
                 <div class="showPicker">
                   {{dateString}}
                 </div>
                   <div class="picker"><a-date-picker  @change="onChange" /></div>
                   <div class="bannerItem">
                     <div>请选择日期</div>
                     <div class="bannerListData">
                       <img src="~@/image/jiantou.png" alt="" srcset=""> 
                     </div>
                   </div>
            </div>
            <div class="item-data item-dataBanner">
                 <span>到期日期</span> 
                 <div class="showPicker">
                   {{dateEnd}}
                 </div>
                   <div class="picker"><a-date-picker  @change="onChangeEnd" /></div>
                   <div class="bannerItem">
                     <div>请选择日期</div>
                     <div class="bannerListData">
                       <img src="~@/image/jiantou.png" alt="" srcset=""> 
                     </div>
                   </div>
            </div>
          </div>
        </div>
        <!-- 备注 -->
        <div class="bntbeizhu">
          <div>备注</div>
          <div class="textArea">
            <textarea></textarea>
          </div>
        </div>
        <!-- 提交 -->
        <div class="submit">
          <img src="../../image/btnBanner.png" alt="" srcset="">
          <div class="addItem" @click="submitGoDealite">添加到仓单</div>
        </div>
      </div>
     </div>
     <div class="modalhiden"  v-if="showHiden">
       <img src="../../image/ru-modeal.png" alt="" srcset="">
     </div>
     </div>
     <!-- 送货管理 -->
    <div v-if="showadd==3">
        <div class="goodsAdmini" >
       <!-- 送货管理 -->
       <div class="titleNav">
         <div class="titleNav_title">
           
         </div>
         <div class="titleNav_text">
           送货管理
         </div>
       </div>
       <div class="item_box_top">
          <div class="item_box">
            <div class="item_box_left">
              <span>*</span>送货公司：
            </div>
            <div class="item_box_right">
               <a-input class="item_box_input" placeholder="请输入送货公司" />  
            </div>
          </div>
          <div class="item_box">
            <div class="item_box_left">
              <span>*</span>送货车牌：
            </div>
            <div class="item_box_right">
               <a-input class="item_box_input" placeholder="请输入送货车牌号码" />  
            </div>
          </div>
          <div class="item_box">
            <div class="item_box_left">
              <span>*</span>是否加工：
            </div>
            <div @click="changejg" class="item_box_right item_box_display">
                <div  :class="{yDivClick:isjg,yDiv:isjgDefalut}">
                  是
                </div>
                <div :class="{yDivClick:isjgNo,yDiv:isjgDefalut}">
                  否
                </div>
            </div>
          </div>
          <!-- 加工商品 -->
            <div class="goods_list">
                <div class="goods_list_item">
                  <div class="goods_list_item_left">
                    一级
                  </div>
                  <div class="goods_list_item_right">
                    <inputSelect :selectArray="selectArray" />
                  </div>
                </div>
                <div class="goods_list_item">
                  <div class="goods_list_item_left">
                    二级
                  </div>
                  <div class="goods_list_item_right">
                    <inputSelect :selectArray="selectArray" />
                  </div>
                </div>
                <div class="goods_list_item">
                  <div class="goods_list_item_left">
                    付款
                  </div>
                  <div class="goods_list_item_right">
                    <inputSelect :selectArray="selectArray" />
                  </div>
                </div>
                <div class="goods_list_item">
                  <div class="goods_list_item_left">
                    数量
                  </div>
                  <div class="goods_list_item_right">
                    <input type="text" class="goods_list_item_input" />
                  </div>
                </div>
                <!-- 备注信息 -->
                <div>
                  <div class="titleName">
                  备注信息
                </div>
                <div class="textareaBeiZhu">
                  <a-textarea placeholder="请填写备注信息：" :rows="4" />
                </div>
                </div>
            </div>
          <div class="goods_list">
              <div class="goods_list_item">
                <div class="goods_list_item_left">
                  一级
                </div>
                <div class="goods_list_item_right">
                  <inputSelect :selectArray="selectArray" />
                </div>
              </div>
              <div class="goods_list_item">
                <div class="goods_list_item_left">
                  二级
                </div>
                <div class="goods_list_item_right">
                  <inputSelect :selectArray="selectArray" />
                </div>
              </div>
              <div class="goods_list_item">
                <div class="goods_list_item_left">
                  付款
                </div>
                <div class="goods_list_item_right">
                  <inputSelect :selectArray="selectArray" />
                </div>
              </div>
              <div class="goods_list_item">
                <div class="goods_list_item_left">
                   数量
                </div>
                <div class="goods_list_item_right">
                   <input type="text" class="goods_list_item_input" />
                </div>
              </div>
              <!-- 备注信息 -->
              <div>
                <div class="titleName">
                备注信息
              </div>
              <div class="textareaBeiZhu">
                 <a-textarea placeholder="请填写备注信息：" :rows="4" />
              </div>
              </div>
          </div>
              <div class="goods_list">
              <div class="goods_list_item">
                <div class="goods_list_item_left">
                  一级
                </div>
                <div class="goods_list_item_right">
                  <inputSelect :selectArray="selectArray" />
                </div>
              </div>
              <div class="goods_list_item">
                <div class="goods_list_item_left">
                  二级
                </div>
                <div class="goods_list_item_right">
                  <inputSelect :selectArray="selectArray" />
                </div>
              </div>
              <div class="goods_list_item">
                <div class="goods_list_item_left">
                  付款
                </div>
                <div class="goods_list_item_right">
                  <inputSelect :selectArray="selectArray" />
                </div>
              </div>
              <div class="goods_list_item">
                <div class="goods_list_item_left">
                   数量
                </div>
                <div class="goods_list_item_right">
                   <input type="text" class="goods_list_item_input" />
                </div>
              </div>
              <!-- 备注信息 -->
              <div>
                <div class="titleName">
                备注信息
              </div>
              <div class="textareaBeiZhu">
                 <a-textarea placeholder="请填写备注信息：" :rows="4" />
              </div>
              </div>
          </div>
          <div class="addForm_item">
            <img src="../../image/addForm.png" class="addForm_img" />
          </div>
          <!-- 入仓产品信息 -->
          <div class="titleNav">
             <div class="titleNav_title">
           
             </div>
             <div class="titleNav_text">
                入仓产品信息
             </div>
          </div>
          <div>
            <goodsItem :placeData="placeOrder"/>
          </div>
       </div>
      
     </div>
      <div class="submitqueRen">
         <div class="submitqueRen_item">
           确认下单
         </div>
       </div>
    </div>
  </div>
</template>
<script>
import inputSelect from "../../components/inputSelect/inputSelect.vue";
import goodsItem from "../../components/goodsItem/goodsItem.vue";
export default {
  name: "inWarehousing",
  data() {
    return {
      itemData: [
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "产品编号:8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "产品编号:8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "产品编号:8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "产品编号:8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "产品编号:8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "产品编号:8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "产品编号:8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "产品编号:8005695004755",
          gge: "130g*12瓶"
        },
        {
          title: "阿米可牌罗勒酱复合调味料",
          bh: "产品编号:8005695004755",
          gge: "130g*12瓶"
        }
      ],
      ModalText: "Content of the modal",
      visible: false,
      confirmLoading: false,
      dateString: "",
      dateEnd: "",
      showHiden: false,
      // 下单号后显示
      showadd: 1,
      isRadite: false,
      // 下单订单
      placeOrder: [
        {
          name: "阿米可牌罗勒酱复合调味料",
          pc1: "fd",
          pc2: "fd",
          rcsl: "fdrcsl",
          guige: "130g*12瓶",
          productDate: "fd",
          goodsName: "fd"
        },
        {
          name: "阿米可牌罗勒酱复合调味料",
          pc1: "fd",
          pc2: "fd",
          rcsl: "fdrcsl",
          guige: "130g*12瓶",
          productDate: "fd",
          goodsName: "fd"
        },
        {
          name: "阿米可牌罗勒酱复合调味料",
          pc1: "fd",
          pc2: "fd",
          rcsl: "fdrcsl",
          guige: "130g*12瓶",
          productDate: "fd",
          goodsName: "fd"
        },
        {
          name: "阿米可牌罗勒酱复合调味料",
          pc1: "fd",
          pc2: "fd",
          rcsl: "fdrcsl",
          guige: "130g*12瓶",
          productDate: "fd",
          goodsName: "fd"
        },
        {
          name: "阿米可牌罗勒酱复合调味料",
          pc1: "fd",
          pc2: "fd",
          rcsl: "fdrcsl",
          guige: "130g*12瓶",
          productDate: "fd",
          goodsName: "fd"
        },
        {
          name: "阿米可牌罗勒酱复合调味料",
          pc1: "fd",
          pc2: "fd",
          rcsl: "fdrcsl",
          guige: "130g*12瓶",
          productDate: "fd",
          goodsName: "fd"
        }
      ],
      // 是否选择加工
      isjg: true,
      isjgNo: false,
      isjgDefalut: true,

      // 选择一级类目
      selectArray: ["选择类目", "Lucy", "Disabled", "yiminghe"],
      isoverfy:true,
      showDeleteHid:true
    };
  },
  components: {
    inputSelect,
    goodsItem
  },
  methods: {
    onSearch(value) {
      console.log(value);
    },
    onChange(date, dateString) {
      this.dateString = dateString;
    },
    // 到期时间
    onChangeEnd(date, dateString) {
      this.dateEnd = dateString;
    },
    clickChanged() {
      this.showHiden = true;
    },
    cloneX() {
      this.showHiden = false;
    },
    clickRadite() {
      this.isRadite = !this.isRadite;
    },
    // 提交订单
    submitGoDealite() {
      this.showadd = 2;
    },
    // 送货管理
    goAdministration() {
      this.showadd = 3;
    },
    // 是否加工
    changejg() {
      this.isjg = !this.isjg;
      this.isjgNo = !this.isjgNo;
    }
  }
};
</script>
<style lang="less" scoped>
.titleNav {
  width: 100%;
  height: 30px;
  line-height: 14px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  background-color: #eeeeee;
  font-weight: bold;
  color: #999999;
  .spannav {
    color: #fd4d02;
  }
}
.item_list_data_xiadan {
  position: relative;
  width: 100%;
  height: 998px;
  padding: 21px 21px 21px 21px;
  overflow: auto;
  .item_list_bannerBorder {
    border: 1px solid #ecb49b;
    padding: 23px 21px 19px 23px;
    margin-bottom: 20px;
  }
  .item_list_banner_select {
    .raditebg {
      border-radius: 50%;
      background-color: #fd4d02;
      width: 14px;
      height: 14px;
    }
    .radite {
      border-radius: 50%;
      border: 1px solid #666666;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
    .textInput {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      margin-left: 15px;
    }
    display: flex;
    // justify-content: center;
    align-items: center;
    justify-content: space-between;
    .item_list_banner_select_right {
      display: flex;
      .item_list_banner_select_icon {
        display: flex;
        cursor: pointer;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #fd4d02;
        .item_list_banner_select_text {
          margin-left: 3px;
        }
      }
      .right22 {
        margin-right: 22px;
      }
    }
  }
  .item_list_banner_box {
    margin-top: 15px;
    display: flex;
    .imgBox {
      width: 83px;
      height: 83px;
    }
    .item_list_banner_Rigth,
    .item_list_banner_Left {
      display: flex;
      flex-direction: column;
      .item_list_banner_LeftData {
        display: flex;
        margin-left: 21px;
        .item_list_banner_LeftName {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #666666;
        }
        .item_list_banner_LeftTitle {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #fd4d02;
          margin-left: 8px;
        }
        .itemColor {
          color: #666666;
        }
      }
    }
    .item_list_banner_Rigth {
      margin-left: 23px;
    }
  }
}
.queRenXia {
  position: absolute;
  width: 930px;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ff4b00;

  .item_list_banner_select {
    margin-left: 30px;
    .raditebg {
      border-radius: 50%;
      background-color: #ffffff;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
    .radite {
      border-radius: 50%;
      border: 1px solid #666666;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }

  .submitQue {
    width: 173px;
    height: 44px;
    background: #ffffff;
    border-radius: 22px;
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ff4b00;
    text-align: center;
    cursor: pointer;
    margin-right: 30px;
  }
}
.item_list {
  width: 100%;
  height: 100%;
  padding: 21px 21px 21px 21px;
  .laoutSearch {
    .search {
      width: 100%;
      height: 40px;
      display: flex;
    }
    .inputSearch {
      width: 571px;
      height: 40px;
      border-radius: 10px;
      line-height: 40px;
      .ant-input-search /deep/.ant-input-group-addon {
        left: -106px;
        z-index: 2;
        height: 30px;
        border-radius: 20px;

        background-color: #eeeeee;
      }
      .ant-input-search /deep/.ant-input-group-addon .ant-input-search-button {
        background-color: #fd4d02;
        border-color: #fd4d02;

        width: 107px;
        height: 30px;
        border-radius: 20px;
      }
      /deep/.ant-input:focus {
        border-color: #eeeeee;
      }
      /deep/.ant-input:hover {
        border-color: #eeeeee;
      }
      /deep/.ant-input-search-enter-button:focus {
        border-color: #eeeeee;
      }
      /deep/.ant-input {
        border-radius: 20px;
        background-color: #eeeeee;
      }
    }
    .item_Right {
      width: 38%;
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: space-between;
      .item_RightList {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 14px;
        // margin-right: 56px;
        span {
          font-size: 14px;
        }
        img {
          height: 20px;
          width: 23px;
        }
      }
    }
  }
  .item_list_data {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: 900px;
    z-index: -1;
    // justify-content: space-between;
    .item_list_banner:nth-child(4n) {
      margin-right: 0;
    }

    .item_list_banner {
      box-sizing: border-box;
      margin-right: 3%;
      background-color: #eeeeee;
      width: 22.5%;
      border-radius: 10px;
      margin-bottom: 23px;

      img {
        width: 100%;
        height: 118px;
      }
      .item_list_list {
        margin-top: 10px;
        margin-left: 12px;
        margin-right: 11px;
        .item_list_miaos {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }
        .item_list_bh {
          margin-top: 7px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #999999;
        }
        .item_list_sub {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: 18px;
          padding-bottom: 10px;
          .item_list_gg {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #666666;
          }
          .item_list_sq {
            height: 29px;
            width: 82px;
            box-sizing: border-box;
            border: 2px solid #ff4b00;
            border-radius: 15px;
            text-align: center;
            line-height: 25px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #ff4b00;
            cursor: pointer;
          }
        }
      }
    }
  }
  .xdNumber {
    height: 69px;
    background: #ff4b00;
    display: flex;
    justify-content: space-between;
    padding: 0 42px 0 41px;
    align-items: center;
    .selected-box {
      display: flex;
      align-items: center;
      .selected {
        margin-right: 10px;
        position: relative;
        background-color: #fff;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        .selected_box {
          position: absolute;
          background-color: #fff;
          height: 25px;
          width: 25px;
          border-radius: 50%;
        }
        img {
          position: absolute;
          margin-top: 3px;
          margin-left: 2px;
        }
      }
      .select_text {
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #e7e7e7;
      }
    }
    .submitxd {
      width: 110px;
      height: 44px;
      line-height: 44px;
      background: #ffffff;
      border-radius: 22px;
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ff4b00;
      text-align: center;
    }
  }
}
.contaiter {
  .modalShow {
    position: fixed;
    width: 565px;
    height: 818px;
    z-index: 22;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      position: absolute;
    }
    .cloneX {
      right: -3%;
      cursor: pointer;
      position: absolute;
    }
    .modalImg {
      box-sizing: border-box;
      padding: 31px 30px 40px 40px;
      .xzrcang {
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        text-align: center;
        margin-bottom: 30px;
      }
      .item-dataBanner {
        display: flex;
        justify-content: space-between;
        .picker {
          position: absolute;
          right: 4%;
          cursor: pointer;

          opacity: 0;
        }
        .bannerItem {
          font-size: 14px;

          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #999999;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 15%;

          cursor: pointer;
        }
        .bannerListData {
          display: flex;

          align-items: center;
        }
      }
      .item-data {
        // margin-top: 44px;
        margin-top: 20px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #999;
        padding-bottom: 10px;
        border-bottom: 1px solid #f0eaea;
        .showPicker {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }
        .item_listin {
          margin-left: 4%;
        }
        .item_input {
          width: 65%;
          border: none;
        }
        .xiang {
          margin-right: 12%;
        }
        .pin {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #999999;
        }
        .item_inputt {
          width: 40%;
          border: none;
        }
        .item_input:focus {
          outline: none;
          border: 1px solid #fff;
        }
      }
    }
    .bntbeizhu {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      display: flex;
      flex-direction: column;
      justify-content: center;
      //  width: 400px;
      margin-left: 40px;
      height: 208px;
      .textArea {
        width: 490px;
        height: 208px;
        opacity: 0.4;
        textarea {
          width: 490px;
          height: 208px;
          resize: none;
        }
      }
    }
    .submit {
      position: relative;
      margin-top: 50px;
      height: 48px;
      display: flex;
      justify-content: center;
      div {
        position: absolute;
        width: 181px;
        height: 48px;
        border-radius: 20px;
        text-align: center;
        line-height: 48px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #fd4d02;
        cursor: pointer;
      }
      img {
        position: absolute;
      }
    }
  }
  .modalhiden {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  }
  .modalShowImg {
    position: absolute;
    height: 818px;
    width: 565px;
  }
}
// 送货管理
.goodsAdmini {
  padding: 20px 24px 0px 18px;
  height: 973px;
  background: #ffffff;
  overflow: auto;
  .titleNav {
    height: 37px;
    width: 100%;
    position: relative;
    .titleNav_title {
      position: absolute;
      background: #fd4d02;
      opacity: 0.1;
      height: 100%;
      width: 100%;
    }
    .titleNav_text {
      position: absolute;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #fd4d02;
      height: 100%;
      width: 100%;
      line-height: 37px;
      margin-left: 19px;
    }
  }
  // 送货公司：
  .item_box_top {
    margin-top: 19px;
  }
  .item_box {
    display: flex;
    margin-bottom: 18px;
    .item_box_left {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      span {
        color: #fd5411;
        margin-right: 5px;
      }
    }
    .item_box_display {
      display: flex;
      .yDiv {
        width: 43px;
        height: 21px;
        color: #fd4d02;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        text-align: center;
        border: 1px solid #fd4d02;
        cursor: pointer;
      }
      .yDivClick {
        color: #fff;
        background-color: #fd4d02;
      }
    }
    .item_box_right {
      width: 264px;
      height: 21px;

      .item_box_input {
        width: 264px;
        height: 21px;
        border: 1px solid #999999 !important;
        opacity: 0.5;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #999999;
      }
      /deep/.a-input:hover {
        border: 1px solid #999999 !important;
        outline: none;
      }
      /deep/.a-input:focus {
        border: 1px solid #999999 !important;
        outline: none;
      }
    }
  }
  .goods_list {
    margin-top: 18px;
    width: 385px;
    border: 1px solid #ecac91;
    padding: 19px 12px 18px 22px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    .goods_list_item {
      display: flex;
      align-items: center;
      height: 25px;
      margin-bottom: 25px;
      .goods_list_item_left {
        margin-right: 20px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #666666;
      }
      .goods_list_item_right {
        .goods_list_item_input {
          width: 120px;
          border: 1px solid #999999;
        }
        /deep/.goods_list_item_input:focus {
          width: 120px;
          outline: 1px solid #999999;
        }
      }
    }
    .titleName {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      margin-bottom: 10px;
    }
    .textareaBeiZhu {
      width: 300px;
      height: 105px;
    }
  }
  .addForm_item {
    height: 26px;
    width: 26px;
    margin-top: 21px;
    margin-bottom: 40px;
    .addForm_img {
      width: 100%;
      height: 100%;
    }
  }

}
  .submitqueRen {
    width: 100%;
    height: 96px;
    background: #ff4b00;
    display: flex;
    justify-content: center;
    align-items: center;
    .submitqueRen_item {
      width: 173px;
      height: 44px;
      background: #ffffff;
      border-radius: 22px;
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ff4b00;
      text-align: center;
    }
  }
</style>
