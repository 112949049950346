import { render, staticRenderFns } from "./inWarehousing.vue?vue&type=template&id=baed2e2a&scoped=true&"
import script from "./inWarehousing.vue?vue&type=script&lang=js&"
export * from "./inWarehousing.vue?vue&type=script&lang=js&"
import style0 from "./inWarehousing.vue?vue&type=style&index=0&id=baed2e2a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baed2e2a",
  null
  
)

export default component.exports